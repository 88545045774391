<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A flexible helium weather balloon of volume
        <number-value :value="V1" unit="\text{m}^3" />
        is released in Pomona where the pressure is
        <stemble-latex content="$746\,\text{mmHg}$" />
        and the temperature is
        <stemble-latex content="$28\,^\circ\text{C.}$" />
        It ascends to an altitude of
        <number-value :value="altitude" unit="\text{ft}" />
        where the pressure is
        <number-value :value="P2" unit="\text{mmHg}" />
        and the temperature is
        <number-value :value="T2" unit="^\circ\text{C.}" />
        What is the new volume of the balloon, in
        <stemble-latex content="$\text{m}^3?$" />
      </p>

      <calculation-input
        v-model="inputs.input1"
        prepend-text="$\text{V}_\text{f}$:"
        append-text="$\text{m}^3$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'ChemCPP1210S4Q2',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    V1() {
      return this.taskState.getValueBySymbol('V1').content;
    },
    altitude() {
      return this.taskState.getValueBySymbol('altitude').content;
    },
    P2() {
      return this.taskState.getValueBySymbol('P2').content;
    },
    T2() {
      return this.taskState.getValueBySymbol('T2').content;
    },
  },
};
</script>
